import { useQuery } from "@tanstack/react-query"
import { useCognitoContext } from "components/Providers/CognitoProvider"
import { queryClient } from "components/Providers/RainbowWagmiProvider"
import { ConnectedUserProfile } from "utils/factories/connectedUserProfileFactory"
import { getUserProfile } from "utils/requests/getUserProfile"

export interface ConnectedUserProfileSelectors {
  userProfile: ConnectedUserProfile | undefined
  error: any
  status: string
  isError: boolean
  isLoading: boolean
}

export interface ConnectedUserProfileActions {
  invalidateUserProfile: () => Promise<void>
  refetch: () => Promise<any>
}

export interface ConnectedUserProfileHook {
  selectors: ConnectedUserProfileSelectors
  actions: ConnectedUserProfileActions
}

export function useConnectedUserProfile(): ConnectedUserProfileHook {
  const { user, session } = useCognitoContext()
  const {
    data: userProfile,
    error,
    status,
    isError,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [`userProfile-${user?.get("sub")}`],
    queryFn: () => {
      if (session === null) {
        throw new Error("No session found")
      }

      return getUserProfile({
        accessToken: session?.getAccessToken().getJwtToken(),
      })
    },
    enabled: !!session?.getAccessToken().getJwtToken() && !!user?.get("sub"),
  })

  const invalidateUserProfile = async () => {
    if (!user?.get("sub")) {
      console.error("No user to invalidate")
      throw new Error("No user to invalidate")
      return
    }

    await queryClient.invalidateQueries({
      queryKey: ["userProfile", user?.get("sub")],
    })
  }

  return {
    selectors: {
      userProfile,
      error,
      status,
      isError,
      isLoading: isLoading || isFetching || isRefetching,
    },
    actions: {
      invalidateUserProfile,
      refetch,
    },
  }
}
